.App {
    text-align: center;
    font-family: monospace;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  div {
    font-family: monospace;
}

span {
    font-family: monospace;
}


  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    font-family: monospace;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  #root > div > header > div > div:nth-child(2) {
      display: none;
  }
  
  #root > div > header > div > div:nth-child(3) {
      display: none;
  }
  
  #root > div > header > div > div:nth-child(1) > div.ant-card-body > div:nth-child(2) {
      display: none!important;
  }
  
  #root > div > header > div > div:nth-child(5) {
      display: none;
  }
  
  #root > div > header > div > div:nth-child(6) {
      display: none;
  }
  
  
  #root > div > header > div > div:nth-child(4) {
      display: none;
  }
  
  body {
   background-image: url("/background.jpg");
  }
  
  #root > div > header > div > div:nth-child(7) > div.ant-card-body > div:nth-child(1) > div {
      display: none;
  }
  
  #root > div > header > div > div:nth-child(7) > div.ant-card-body > div:nth-child(1) > input {
      display: none;
  }
  
  #root > div > header > div > div:nth-child(7) > div.ant-card-body > div:nth-child(2) {
    display: none;
  }
  
  button.ant-btn.css-dev-only-do-not-override-12jzuas.ant-btn-default {
    color: red!important;
    background-color: #2eff2e!important;
}